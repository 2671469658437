import { ISelectOption } from 'src/components/ui/SelectOption'
import { IRelatedDocument } from 'src/components/document/document-types'
import { IDocument, IDocumentGroup } from 'src/templates/documents'
import { isBrowser } from 'src/helpers/layout'

const filterBySelectOption = (
  documents: Array<IRelatedDocument | IDocument>,
  selectOptionToFilter: ISelectOption,
  fieldToFilter: string
) => {
  if (selectOptionToFilter && selectOptionToFilter.optionId === 'all') {
    return documents
  }

  return documents.filter((document) => {
    if (!selectOptionToFilter) {
      return true
    }

    if (!document[fieldToFilter]) {
      return false
    }

    if (Array.isArray(document[fieldToFilter])) {
      return !!document[fieldToFilter].find(
        ({ optionId }) => optionId === selectOptionToFilter.optionId
      )
    }

    return document[fieldToFilter].optionId === selectOptionToFilter.optionId
  })
}

export const isSetParamInUrl = (key: string): boolean => {
  if (!isBrowser) {
    return false
  }
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  return !!params[key]
}

export default filterBySelectOption

export const findDocumentTypeById = (
  id: string | undefined,
  documents: ISelectOption[]
) => {
  if (!id) {
    return null
  }
  return documents.find((documentType) => id === documentType.optionId)
}

export const findYearById = (
  id: string | undefined,
  yearTypes: ISelectOption[]
) => {
  if (!id) {
    return null
  }
  return yearTypes.find((documentType) => id === documentType.optionId)
}

export const findLanguageById = (
  id: string | undefined,
  languages: ISelectOption[]
) => {
  if (!id) {
    return null
  }
  return languages.find((documentType) => id === documentType.optionId)
}

export const setUrlParam = (param: string, value: string) => {
  const url = new URL(window.location)
  url.searchParams.set(param, value)
  window.history.pushState({}, '', url)
}

export const groupDocumentsByType = (
  documents: IDocument[]
): IDocumentGroup[] =>
  documents.reduce((acc, documentToBeGrouped) => {
    const suitableCategory = acc.find(
      (documentGroup) =>
        documentGroup.documentType?.optionId ===
        documentToBeGrouped.documentType?.optionId
    )

    if (suitableCategory) {
      return acc.map((accItem) => {
        const isCorrectCategory =
          suitableCategory.documentType?.optionId ===
          accItem.documentType?.optionId

        if (isCorrectCategory) {
          return {
            ...accItem,
            documents: [...accItem.documents, documentToBeGrouped],
          }
        }

        return accItem
      })
    }
    return [
      ...acc,
      {
        documentType: documentToBeGrouped.documentType,
        documents: [documentToBeGrouped],
      },
    ]
  }, [])

export const sortDocumentGroups = (documentGroups: IDocumentGroup[]) =>
  documentGroups.sort((a, b) => {
    if (!a.documentType) {
      return 1
    }

    if (!b.documentType) {
      return -1
    }

    return a.documentType.position - b.documentType.position
  })

import React, { FC, useContext } from 'react'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IDocument } from 'src/templates/laws-of-the-game-documents'

interface ISingleLawsOfTheGameDocumentProps {
  document: IDocument
}

const StyledDocument = styled.div`
  &:nth-child(2n + 2) {
    ${tw`pr-0`}
  }
`

const SingleLawsOfTheGameDocument: FC<ISingleLawsOfTheGameDocumentProps> = ({
  document,
}) => {
  const lngContext = useContext(I18nextContext)
  const url = document.externalLink
    ? document.externalLinkUrl
    : `/downloads/${document.slug}?l=${lngContext.language}`

  return (
    <StyledDocument className="w-full md:w-1/2 pr-3 mt-12 md:mt-6">
      <a
        href={url || undefined}
        rel="noreferrer"
        className="hidden sm:block"
        target="_blank"
      >
        <img
          src={document.desktopImage && document.desktopImage.url}
          className="w-full block mr-2"
          alt={document.name || 'document'}
        />
      </a>
      <a
        href={url || undefined}
        rel="noreferrer"
        className="sm:hidden"
        target="_blank"
      >
        <img
          src={document.mobileImage && document.mobileImage.url}
          className="w-full block"
          alt={document.name || 'document'}
        />
      </a>
    </StyledDocument>
  )
}

export default SingleLawsOfTheGameDocument
